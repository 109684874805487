import React from "react"
import ServiceSidebarArchitecture from "./ServiceSidebarArchitecture"
import details1 from "../../assets/images/services/banner-web-development2.jpg"

const WebArchitectureContent = ({ location }) => {
  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="services-details-image">
              <img src={details1} alt="about" />
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <ServiceSidebarArchitecture location={location} />
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <span className="sub-title">SOFTWARE ARCHITECTURE DESIGN</span>
              <h3>Web Architecture</h3>
              <p>
                <h5>
                  Design of GUI Application using Angular, React, Vue
                  technologies. Porting existing web apps from PHP, UI5, JSP to
                  Angular, React, Vue technologies.
                </h5>
              </p>
              <p>
                Web Application technology changes quickly as new frameworks
                appear every week. Effective use of emerging technology becomes
                harder for development teams. A correct choice for a library or
                plugin today may decide the success or failure of a project in
                the future. To keep up with technology advancements and provide
                the best experience for your customers & users, we recommend
                periodic updates of project technology, and continuous quality
                assurance of your software solutions.
              </p>
              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="content">
                    <h3 className="pt-30">
                      Typical software challenges in existing projects
                    </h3>
                    <ul>
                      <li>
                        Monolithic frontend design that slows down development
                        and time to market delay for new features
                      </li>
                      <li>
                        Slower web reaction times for user input due to
                        inefficient resource optimisation
                      </li>
                      <li>
                        Problems with responsive display on various devices
                      </li>
                      <li>· No analytics to understand user behaviour</li>
                      <li>
                        Slower development of new features caused by old
                        technology stack
                      </li>
                      <li>
                        Security problems with user session management and bad
                        authentication flows
                      </li>
                      <li>Low team productivity caused by technical debt</li>
                      <li>
                        No technical documentation for existing code slows team
                        on-boarding and create risks when team grows or members
                        rotate
                      </li>
                      <li>No UI tests for critical user flows</li>
                      <li>No tools for guided user support in frontend</li>
                    </ul>
                  </div>
                </div>
              </div>
              <p>
                As a result, we prepared a number of solutions in the form of
                architecture services that we can provide for a software
                frontend project:
              </p>
              <div className="content">
                <ul>
                  <li>
                    <b>Modularisation</b> - Split of one frontend to smaller
                    modules that could be managed separately{" "}
                  </li>
                  <li>
                    <b>Upgrade to newer technology stack</b> – port to AngularJS
                    or ReactJS from JSP, PHP, Struts, etc.
                  </li>
                  <li>
                    <b>Create missing documentation</b> – includes functional
                    specification, architecture high level design, technical
                    design & specification for various domain modules,
                    description of used APIs
                  </li>
                  <li>
                    <b>Security check for frontend app</b> – APIs, proper role
                    usage, cross site attacks, SSO flow check
                  </li>
                  <li>
                    <b>Enhanced user experience</b> – check & update of user
                    flows, extending solution with additional features like
                    asynchronous messaging, check and fix for responsive
                    rendering for all relevant devices, UX update to newer look
                    & feel.
                  </li>
                  <li>
                    <b>Analytics Solution for Frontend</b> – a complete solution
                    to monitor and analyse traffic and business events on your
                    frontend site in order to understand your user’s behavior.
                    Software design for existing solution, integration design,
                    help with setup and deployment in production.
                  </li>
                  <li>
                    <b>UI Test Automation</b> – preparation of CI/CD UI
                    automatic regression tests framework for critical areas of
                    project functionality. Allows to incorporate UI Tests in
                    existing project development flows
                  </li>
                  <li>
                    <b>Technical Event Monitoring</b> – empowers support team to
                    better and faster diagnose existing user issues in
                    production and development environments
                  </li>
                </ul>
              </div>
              <h3 className="pt-30">
                Typical software challenges for new projects
              </h3>
              <div className="content">
                <ul>
                  <li>
                    Difficult estimation phase because of a new functional or
                    technical project area
                  </li>
                  <li>Unclear scope – typical for startups</li>
                  <li>
                    No Architecture & Development standard for designers and
                    programmers
                  </li>
                  <li>
                    No tools for faster review cycle in interactive development
                  </li>
                  <li>No testing strategy for CI/CD automatic tests</li>
                </ul>
              </div>
              <h5>
                If you encountered mentioned challenges don't hesitate to
                contact us.
              </h5>

              <h4 className="pt-30">
                Here some of the services that we provide to secure successful
                new project's outcome:
              </h4>
              <div className="content">
                <ul>
                  <li>
                    Architecture design for new frontend application technical
                    and functional modules
                  </li>
                  <li>
                    Help with project estimations around technical and
                    functional features
                  </li>
                  <li>
                    Verification of functional roadmap against technical
                    capabilities
                  </li>
                  <li>Security design for APIs, SSO flows & roles concept</li>
                  <li>Setup of functional and technical tests for web apps</li>
                  <li>
                    Audit and tracing capabilities for security crucial features
                  </li>
                </ul>
              </div>

              <h3 className="pt-30">For Who?</h3>
              <div className="content">
                <ul>
                  <li>
                    Non-IT focused companies building software in house for
                    their internal needs, such as financial services, energy,
                    eCommerce sector, etc.
                  </li>
                  <li>
                    SaaS companies building MVP or scaling existing MVP for
                    wider audience
                  </li>
                  <li>
                    Software product companies that would like to optimise
                    architecture in internal product projects
                  </li>
                  <li>
                    Software house companies that would like to optimise
                    architecture of external customer projects done in house
                  </li>
                  <li>
                    Software service companies that would like to strengthen
                    their position in customer projects with external
                    architecture services
                  </li>
                  <li>
                    Software companies that want to have technical support at
                    writing project offers
                  </li>
                </ul>
              </div>

              <h3 className="pt-30">Industries</h3>
              <p>
                Our services are usable in many industries. However most of our
                experience has been gathered during projects in financial and
                insurance services. This makes Arch Forge Solutions better
                positioned for helping projects in those industries, as we can
                bring additional functional expertise besides our technical
                know-how.
              </p>
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-investment"></i>
                    </div>
                    Banking
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-hospital"></i>
                    </div>
                    Healthcare
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-factory"></i>
                    </div>
                    Manufacturing
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-house"></i>
                    </div>
                    Real Estate
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-order"></i>
                    </div>
                    Logistics
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-tracking"></i>
                    </div>
                    Automobile
                  </div>
                </div>
              </div>
              <h3 className="pt-30">Technology</h3>
              <p>
                Technologies & tools that we typically use in our web projects.
              </p>
              <ul className="technologies-features">
                <li>
                  <span>NodeJS</span>
                </li>
                <li>
                  <span>Angular</span>
                </li>
                <li>
                  <span>ReactJS</span>
                </li>
                <li>
                  <span>Vue.js</span>
                </li>
                <li>
                  <span>Gatsby</span>
                </li>
                <li>
                  <span>Strapi</span>
                </li>
                <li>
                  <span>Joomla</span>
                </li>
                <li>
                  <span>Laravel</span>
                </li>
                <li>
                  <span>Yii</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WebArchitectureContent
